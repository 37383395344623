<template v-if="trait_values">
  <FixedRatioContainer>
    <div class="grid-box p-8 w-full h-full flex flex-col-reverse md:flex-row overflow-scroll no-scrollbar md:overflow-auto">
      <div class="flex flex-col w-full md:w-1/2 h-auto md:h-full md:overflow-scroll no-scrollbar">
        <div v-for="(trait_names, header) in $store.getters['character/big_five']" :key="header"
             class="bg-gray-200 my-2 rounded-xl px-3 cursor-pointer"
             @click="selectHeader(header)">
          <TraitBar :id="header" :custom-class="'font-bold p-1'" :header="header" :vertical="false">
          </TraitBar>
          <p v-if="selectedHeader === header" class="mx-2 block md:hidden">{{
            $t('characterOverview.' +
                selectedHeader.toLowerCase())
          }}</p>
          <div v-if="selectedHeader === header">
            <TraitBar v-for="(trait_name, index) in trait_names" :key="index" :custom-class="'my-2'" :header="header"
                      :trait="trait_name"
                      :vertical="false"></TraitBar>
          </div>
          <div v-if="selectedHeader !== header" class="text-center flex justify-center items-center">
            <i class="fas fa-angle-down"></i>
          </div>
          <div v-else class="text-center">
            <i class="fas fa-angle-up"></i>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full md:w-1/2 text-center">
        <h1 class="mb-2 md:my-5">{{ $t('characterOverview.title') }}</h1>
        <p v-if="selectedHeader" class="mx-2 hidden md:block"><b>{{ selectedHeaderTranslated() }}</b>: {{
            $t('characterOverview.' +
                selectedHeader.toLowerCase())
          }}</p>
        <p v-if="!selectedHeader">{{ $t('characterOverview.instructions') }}</p>
        <img v-if="!selectedHeader"
             class="max-h-36 md:max-h-48 my-5 mx-auto"
             src="../assets/character_overview_dude.png"/>
      </div>
    </div>
  </FixedRatioContainer>
</template>

<script>
import FixedRatioContainer from "@/components/FixedRatioContainer";
import TraitBar from "@/components/TraitBar";

export default {
  components: {FixedRatioContainer, TraitBar},
  name: "CharacterOverview",
  data() {
    return {
      selectedHeader: null
    }
  },
  methods: {
    selectHeader(header) {
      if (this.selectedHeader === header) {
        this.selectedHeader = null;
      } else {
        this.selectedHeader = header;
        this.$nextTick(() => {
          document.getElementById(header).scrollIntoView();
        });
      }
    },
    selectedHeaderTranslated() {
      if (this.$store.getters['account/selected_locale'] === 'de') {
        return this.$store.getters['character/big_five_de'][this.selectedHeader];
      }
      return this.selectedHeader;
    }
  }
}
</script>
